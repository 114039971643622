import {
  Button,
  ButtonDesign,
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Label,
  MultiInput,
  Token,
} from '@fioneer/ui5-webcomponents-react'
import compact from 'lodash.compact'
import uniqBy from 'lodash.uniqby'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dealsPermissions } from 'api/deals/dealsAllowedOperations'
import { WorkingVersionType } from 'components/domains/deals/deal-adjustment/model/WorkingVersionType'
import { DealFilterBar, DealFilterKeys } from 'components/domains/deals/deal-search/DealFilterBar'
import styles from 'components/domains/deals/deal-search/DealSearchDialog.module.css'
import DealSearchTable, {
  DealSearchTableColumn,
  DealSearchTableMode,
} from 'components/domains/deals/deal-search/DealSearchTable'
import Dialog, {
  DialogPrimaryButton,
  DialogSecondaryButton,
  DialogSize,
} from 'components/ui/dialog/Dialog'
import CenteredIllustratedMessage from 'components/ui/illustrated-message/CenteredIllustratedMessage'
import { useDealPermissions } from 'hooks/services/deals/useDealPermissions'
import { useDealsInfiniteRequest } from 'hooks/services/deals/useDeals'

const buildInitialSorting = () => ({
  orderField: DealFilterKeys.CreatedAt,
  orderDirection: 'desc',
})

/**
 * DealSearchDialog
 * - columnsRestrictedTo: null | Array<DealSearchTableColumn>
 * - initialFilterValues: null | {[key in DealFilterKeys]: string }
 * - visibleFilters: null | Array<DealFilterKeys>
 * - readOnlyFilters: null | Array<DealFilterKeys>
 */
const DealSearchDialog = ({
  isOpen,
  setIsOpen,
  onClose = () => {},
  onSelected,
  isMultiSelect,
  columnsRestrictedTo,
  initialFilterValues: passedInitialFilterValues = {},
  visibleFilters,
  readOnlyFilters = [],
  initiallySelectedDeals = [],
  lockedSelectedDeals = [],
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.deals.deal-search.dialog' })
  const { t: tNoPrefix } = useTranslation()

  const liveAndWorkingVersionFilter = {
    [DealFilterKeys.WorkingVersion]: Object.values(WorkingVersionType).join(','),
  }
  const initialFilterValues = useMemo(
    () => ({
      ...liveAndWorkingVersionFilter,
      ...passedInitialFilterValues,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [passedInitialFilterValues],
  )

  // locked deals can also be part of the initially selected deals, but should not be shown twice
  const uniqueSelectedDeals = useMemo(
    () =>
      uniqBy([...(initiallySelectedDeals ?? []), ...(lockedSelectedDeals ?? [])], 'dealUuid') ?? [],
    [initiallySelectedDeals, lockedSelectedDeals],
  )

  const [filterParams, setFilterParams] = useState(initialFilterValues)
  const [isInitialState, setIsInitialState] = useState(true)
  const [isFilterTriggered, setIsFilterTriggered] = useState(false)
  const [sortingParams, setSortingParams] = useState(buildInitialSorting())

  // selected dialog deals: all deals selected throughout the whole interaction with the deal popup
  const [selectedDialogDeals, setSelectedDialogDeals] = useState(uniqueSelectedDeals ?? [])
  const selectedDialogDealUuids = useMemo(
    () => selectedDialogDeals.map((deal) => deal.dealUuid),
    [selectedDialogDeals],
  )

  const isDealLocked = useCallback(
    (dealUuid) =>
      lockedSelectedDeals?.some(({ dealUuid: lockedDealUuid } = {}) => lockedDealUuid === dealUuid),
    [lockedSelectedDeals],
  )

  useEffect(() => {
    if (isOpen) {
      setSelectedDialogDeals(uniqueSelectedDeals)
    }
    // since this dialog is not always rendered conditionally we need to trigger this effect
    // when the open flag changes but not necessarily when the
    // initialBusinessPartners change (that would magically select or deselect BPs without the user clicking somewhere)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const {
    isFetching: isFetchingAllowedOperations,
    isError: isErrorAllowedOperations,
    data: { allowedOperations = [] } = {},
  } = useDealPermissions()

  const isAllowedToReadDeal = allowedOperations.includes(dealsPermissions.readDeal)

  // table deals: deals currently visible in the search results
  const workingVersionFilter = filterParams[DealFilterKeys.WorkingVersion]
    ? filterParams[DealFilterKeys.WorkingVersion]
    : liveAndWorkingVersionFilter[DealFilterKeys.WorkingVersion]
  const {
    isFetching: isFetchingDeals,
    isLoading: isLoadingDeals,
    isError: isErrorDeals,
    data: dealsPaginated,
    fetchNextPage: loadMoreDeals,
  } = useDealsInfiniteRequest({
    filter: {
      dealId: filterParams[DealFilterKeys.Id],
      dealName: filterParams[DealFilterKeys.Name],
      accountManager: filterParams[DealFilterKeys.AccountManager],
      businessSegments: filterParams[DealFilterKeys.BusinessSegments],
      dealTypes: filterParams[DealFilterKeys.Type],
      originationPlatform: filterParams[DealFilterKeys.OriginationPlatform],
      originationTeam: filterParams[DealFilterKeys.OriginationTeam],
      status: filterParams[DealFilterKeys.Status],
      borrowerId: filterParams[DealFilterKeys.Borrower],
      workingVersion: workingVersionFilter,
    },
    sort: sortingParams,
    queryOptions: { enabled: isFilterTriggered },
  })

  const tableDeals = useMemo(
    () => dealsPaginated?.pages?.flatMap((page) => [...page.deals]) || [],
    [dealsPaginated?.pages],
  )

  const propertiesDataTotal = useMemo(
    () => (dealsPaginated?.pages?.length ? dealsPaginated.pages[0].pagination.total : 0),
    [dealsPaginated?.pages],
  )

  const numberOfVisibleEntries =
    dealsPaginated?.pageParams?.at(-1)?.offset + dealsPaginated?.pageParams?.at(-1)?.limit ||
    tableDeals?.length

  const pagination = useMemo(
    () =>
      !isFetchingDeals && !isErrorDeals && tableDeals?.length
        ? { limit: numberOfVisibleEntries, total: propertiesDataTotal }
        : { limit: 0, total: 0 },
    [
      isErrorDeals,
      isFetchingDeals,
      numberOfVisibleEntries,
      propertiesDataTotal,
      tableDeals?.length,
    ],
  )

  useEffect(() => {
    if (isInitialState && isFilterTriggered && !isLoadingDeals) {
      setIsInitialState(false)
    }
  }, [isFilterTriggered, isInitialState, isLoadingDeals])

  const updateFilters = (_filterParams, _sortingParams) => {
    setFilterParams(_filterParams)
    setSortingParams(_sortingParams)
  }

  const handleOnClose = () => {
    setIsInitialState(true)
    setIsFilterTriggered(false)
    setIsOpen(false)
    onClose()
  }
  const handleOnOkButton = () => {
    onSelected(selectedDialogDeals)
    handleOnClose()
  }
  const isAnyDealSelected = () => selectedDialogDealUuids.length > 0

  const handleFilterbarGo = (newFilters) => {
    setIsFilterTriggered(true)
    updateFilters(newFilters, sortingParams)
  }

  const onSortingChanged = (orderField, orderDirection) => {
    const newSortingParams = { orderField, orderDirection }
    updateFilters(filterParams, newSortingParams)
  }

  const onSelectionChanged = (selectionEvent, toggleSingleDeal) => {
    const selectedTableRows = selectionEvent.detail.selectedRows
    const selectedTableDeals = selectedTableRows
      .map(({ dataset: { dealId, dealUuid, dealName } }) => ({
        dealId,
        dealUuid,
        dealName,
      })) // remove locked selected deals and then append it later
      ?.filter(({ dealUuid } = {}) => !isDealLocked(dealUuid))
    if (selectedTableDeals.length === 0) {
      return
    }

    if (toggleSingleDeal) {
      const selectedDealUuid = selectedTableDeals?.[0]?.dealUuid
      if (selectedDialogDealUuids.includes(selectedDealUuid)) {
        setSelectedDialogDeals((previous) =>
          previous.filter(({ dealUuid }) => dealUuid !== selectedDealUuid),
        )
      } else {
        // the selected deals need to be checked for uniqueness since a click on the select box (not the row) triggers both cases
        setSelectedDialogDeals((previous) =>
          uniqBy([...previous, selectedTableDeals[0]], 'dealUuid'),
        )
      }
      return
    }
    // previous deals:
    // (dialog) deals that were selected from the deal table before & are currently NOT visible in the (table) deals
    // (example: the user did a search - selected some deals - then did a completely different search)
    // since the propagated selectedTableDeals only operate on the currently visible (table) deals,
    // all previously selected deals need to be preserved
    const currentlyVisibleDealUuids = tableDeals.map((d) => d.dealUuid)
    const previouslySelectedDeals = selectedDialogDeals.filter(
      (selectedDeal) => !currentlyVisibleDealUuids.includes(selectedDeal.dealUuid),
    )
    const updatedSelectedDeals = [
      ...lockedSelectedDeals,
      ...previouslySelectedDeals,
      ...selectedTableDeals,
    ]
    setSelectedDialogDeals(updatedSelectedDeals)

    // Select the last added deal and close the dialog in single select case
    if (!isMultiSelect) {
      onSelected(selectedTableDeals)
      handleOnClose()
    }
  }

  const handleFooterTokenDelete = useCallback(
    (deleteEvent) => {
      const deleteDealId = deleteEvent.detail.token.dataset.dealId
      // do not delete locked selected deals
      if (isDealLocked(deleteDealId)) {
        return
      }

      setSelectedDialogDeals((prev) => [
        ...prev.filter(({ dealUuid }) => dealUuid !== deleteDealId),
      ])
    },
    [isDealLocked],
  )

  const handleFooterTokenClearAll = useCallback(() => {
    // reset selected deals to locked selected deals (since they should never be removed)
    setSelectedDialogDeals(lockedSelectedDeals ?? [])
  }, [lockedSelectedDeals])

  const renderTable = () => (
    <DealSearchTable
      deals={tableDeals}
      mode={isMultiSelect ? DealSearchTableMode.MultiSelect : DealSearchTableMode.SingleSelect}
      pagination={pagination}
      orderField={sortingParams.orderField}
      orderDirection={sortingParams.orderDirection}
      onSortingChanged={onSortingChanged}
      onSelectionChanged={onSelectionChanged}
      selectedDealUuids={selectedDialogDealUuids}
      columnsRestrictedTo={columnsRestrictedTo}
      isError={isErrorDeals || isErrorAllowedOperations}
      isLoading={isFetchingDeals || isFetchingAllowedOperations}
      isAllowedToReadDeal={isAllowedToReadDeal}
      loadMore={loadMoreDeals}
      additionalToolbarProperties={{ showColumnSelection: false, sorting: null }}
      showInitPlaceholder={isInitialState}
      lockedSelectedDeals={lockedSelectedDeals}
    />
  )

  const renderFooter = () =>
    isMultiSelect && !isErrorDeals && !isErrorAllowedOperations ? (
      <>
        <Label className={styles.titleLabel}>
          {t('selected-deals', { count: selectedDialogDeals.length })}
        </Label>
        <FlexBox className={styles.selectionRow} alignItems={FlexBoxAlignItems.Center}>
          <MultiInput
            maxlength={0}
            onTokenDelete={handleFooterTokenDelete}
            className={styles.footerInput}
            tokens={
              <>
                {selectedDialogDeals.map((deal) => {
                  if (
                    lockedSelectedDeals?.some(({ dealUuid } = {}) => dealUuid === deal.dealUuid)
                  ) {
                    // remove deletion options for locked selected deals
                    return (
                      <Token
                        key={deal.dealUuid}
                        text={`${deal.dealName} (${deal.dealId})`}
                        data-deal-id={deal.dealUuid}
                        closeIcon={<div className={styles.emptyIcon} />}
                      />
                    )
                  }
                  return (
                    <Token
                      key={deal.dealUuid}
                      text={`${deal.dealName} (${deal.dealId})`}
                      data-deal-id={deal.dealUuid}
                    />
                  )
                })}
              </>
            }
          />
          <Button
            icon="decline"
            design={ButtonDesign.Transparent}
            onClick={handleFooterTokenClearAll}
          />
        </FlexBox>
      </>
    ) : (
      <></>
    )

  const noPermissionsIllustratedMessage = (
    <CenteredIllustratedMessage
      name="UnableToLoad"
      size="Spot"
      titleText={t('not-allowed.title')}
      subtitleText={t('not-allowed.subtitle')}
    />
  )
  return (
    <Dialog
      open={isOpen}
      size={DialogSize.XL}
      className={styles.dealSearchDialog}
      headerText={t('title')}
      primaryButton={
        isMultiSelect && (
          <DialogPrimaryButton
            onClick={handleOnOkButton}
            design={ButtonDesign.Emphasized}
            disabled={!isAnyDealSelected()}
          >
            {tNoPrefix('buttons.ok')}
          </DialogPrimaryButton>
        )
      }
      onBeforeClose={(e) => e?.detail?.escPressed && handleOnClose()}
      closeButton={
        <DialogSecondaryButton onClick={handleOnClose}>
          {tNoPrefix('buttons.cancel')}
        </DialogSecondaryButton>
      }
    >
      {!isFetchingAllowedOperations && !isAllowedToReadDeal ? (
        noPermissionsIllustratedMessage
      ) : (
        <FlexBox direction={FlexBoxDirection.Column} className={styles.dialogContentWrapper}>
          <DealFilterBar
            onGo={handleFilterbarGo}
            isOpen={isOpen}
            initialValues={initialFilterValues}
            visibleFilters={visibleFilters}
            readOnlyFilters={readOnlyFilters}
            additionalFilterBarProperties={{ hideFilterConfiguration: true }}
          />
          <div
            className={compact([
              styles.tableWrapper,
              !isInitialState && styles.fixTableHeight,
            ]).join(' ')}
          >
            {renderTable()}
          </div>
          <FlexBox
            direction={FlexBoxDirection.Column}
            justifyContent={FlexBoxJustifyContent.End}
            className={styles.footerWrapper}
          >
            {renderFooter()}
          </FlexBox>
        </FlexBox>
      )}
    </Dialog>
  )
}

DealSearchDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onSelected: PropTypes.func.isRequired,
  isMultiSelect: PropTypes.bool.isRequired,
  columnsRestrictedTo: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DealSearchTableColumn))),
  // eslint-disable-next-line react/forbid-prop-types
  initialFilterValues: PropTypes.object,
  visibleFilters: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DealFilterKeys))),
  readOnlyFilters: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DealFilterKeys))),
  initiallySelectedDeals: PropTypes.arrayOf(
    PropTypes.shape({
      dealId: PropTypes.string.isRequired,
      dealName: PropTypes.string.isRequired,
      dealUuid: PropTypes.string.isRequired,
    }),
  ),
  lockedSelectedDeals: PropTypes.arrayOf(
    PropTypes.shape({
      dealId: PropTypes.string.isRequired,
      dealName: PropTypes.string.isRequired,
      dealUuid: PropTypes.string.isRequired,
    }),
  ),
}

export default DealSearchDialog
