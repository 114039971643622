import { Button, ButtonDesign, FlexBox } from '@fioneer/ui5-webcomponents-react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import React from 'react'
import styles from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsCardHeaderActions.module.css'

const PropertyRentRollSegmentsCardHeaderActions = ({
  disabled,
  onEdit,
  onSave,
  onCancel,
  isAllowedPropertySegmentsUpdate,
  inEditMode,
}) => {
  if (!isAllowedPropertySegmentsUpdate) {
    return <></>
  }

  if (!inEditMode) {
    return (
      <Button id={'segment-table-edit-action'} onClick={onEdit} design={ButtonDesign.Transparent}>
        {t('buttons.edit')}
      </Button>
    )
  } else {
    return (
      <FlexBox>
        <Button
          id={'segment-table-save-action'}
          disabled={disabled}
          onClick={!disabled ? onSave : null}
          design={ButtonDesign.Emphasized}
        >
          {t('buttons.save')}
        </Button>
        <Button
          id={'segment-table-cancel-action'}
          className={styles.marginLeft}
          onClick={onCancel}
          design={ButtonDesign.Transparent}
        >
          {t('buttons.cancel')}
        </Button>
      </FlexBox>
    )
  }
}
PropertyRentRollSegmentsCardHeaderActions.propTypes = {
  disabled: PropTypes.bool,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isAllowedPropertySegmentsUpdate: PropTypes.bool,
  inEditMode: PropTypes.bool,
}

export default PropertyRentRollSegmentsCardHeaderActions
