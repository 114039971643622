import {
  FlexBox,
  FlexBoxJustifyContent,
  Text,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useFormatPercentageOrValueWithUnit from 'components/domains/properties/monitoring/useFormatPercentageOrValueWithUnit'
import styles from 'components/ui/charts/kpi-chart/KpiChartTooltip.module.css'
import {
  useCustomizableCurrencyFormatter,
  useNumberFormatter,
  useShortDateFormatter,
} from 'hooks/i18n/useI18n'

const KpiChartTooltip = ({ active, payload, kpiUnit }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ui.charts.property-monitoring-kpi-chart-tooltip',
  })

  const formatCurrency = useCustomizableCurrencyFormatter()
  const numberFormatterProps = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }
  const formatNumber = useNumberFormatter(numberFormatterProps)
  const formatPercentageOrValueWithUnit = useFormatPercentageOrValueWithUnit({
    numberFormatterProps,
  })
  const { format } = useShortDateFormatter()

  const value = useMemo(() => {
    if (!active || !payload || !payload.length > 0) return

    if (!kpiUnit) return formatNumber(payload[0].payload.value)

    try {
      return `${formatCurrency(payload[0].payload.value, kpiUnit, {
        currencyDisplay: 'code',
      })}`
    } catch (error) {
      return formatPercentageOrValueWithUnit({
        value: payload[0].payload.value,
        unit: kpiUnit,
      })
    }
  }, [active, formatCurrency, formatNumber, formatPercentageOrValueWithUnit, kpiUnit, payload])

  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltip}>
        <Text className={styles.validityDate} level={TitleLevel.H5}>
          {format(payload[0].payload.validity_date)}
        </Text>
        <FlexBox
          className={styles.paddingBottom}
          justifyContent={FlexBoxJustifyContent.SpaceBetween}
        >
          <Title level={TitleLevel.H5}>{t('value')}</Title>
          <Title level={TitleLevel.H5}>{value}</Title>
        </FlexBox>
        <FlexBox justifyContent={FlexBoxJustifyContent.SpaceBetween}>
          <Title level={TitleLevel.H5}>{t('receipt-date')}</Title>
          <Title level={TitleLevel.H5}>{format(payload[0].payload.receipt_date)}</Title>
        </FlexBox>
      </div>
    )
  }

  return null
}

KpiChartTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        receipt_date: PropTypes.string,
        validity_date: PropTypes.string,
        value: PropTypes.number,
      }),
    }),
  ),
  kpiUnit: PropTypes.string,
}

export default KpiChartTooltip
