import '@ui5/webcomponents-fiori/dist/illustrations/sapIllus-Spot-UnableToLoad.js'
import '@ui5/webcomponents-fiori/dist/illustrations/NoTasks'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash.isempty'
import uniq from 'lodash.uniq'
import { useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styles from 'components/domains/properties/insurances/PropertyInsurancesTable.module.css'
import PropertyInsurancesTableConfig from 'components/domains/properties/insurances/PropertyInsurancesTableConfig'
import RentRollBusinessPartnerSearchDialog from 'components/domains/rentroll/RentRollBusinessPartnerSearchDialog'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import CardWithDisplayAndEditTable from 'components/ui/tables/display-and-edit-table/CardWithDisplayAndEditTable'
import { rowKeyNewRow } from 'components/ui/tables/display-and-edit-table/constants'
import { useNumberFormatter } from 'hooks/i18n/useI18n'
import useBusinessPartnerMiniByIds from 'hooks/services/business-partners/minis/useBusinessPartnerMiniByIds'
import { useMatchingBusinessPartnersByNameOrId } from 'hooks/services/business-partners/searchBusinessPartners'
import { useAllowedInsuranceTypes } from 'hooks/services/properties/insurances/useAllowedInsuranceTypes'
import { useCreatePropertyInsurance } from 'hooks/services/properties/insurances/useCreatePropertyInsurance'
import { useDeletePropertyInsurance } from 'hooks/services/properties/insurances/useDeletePropertyInsurance'
import { useInsuranceTypes } from 'hooks/services/properties/insurances/useInsuranceTypes'
import { usePropertyInsurances } from 'hooks/services/properties/insurances/usePropertyInsurances'
import { useUpdatePropertyInsurance } from 'hooks/services/properties/insurances/useUpdatePropertyInsurance'
import { useCurrencyCodes } from 'hooks/services/properties/useCurrencyCodes'
import useMultiPropertyValuations from 'hooks/services/properties/valuations/useMultiPropertyValuations'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'
import { PropertyContext } from 'routes/properties/PropertyContext'

const PropertyInsurancesTable = () => {
  const { t: tPropertyInsurancesTable } = useTranslation('translation', {
    keyPrefix: 'pages.property-overview.insurances.table',
  })
  const formatNumber = useNumberFormatter({ minimumFractionDigits: 2 })
  const { property } = useContext(PropertyContext)
  const allowedOperations = property.allowed_operations?.allowed_operations ?? []
  const propertyIsUnderReview = property.change_request_exist_indicator
  const userIsAllowedToEdit =
    propertyIsUnderReview === false && allowedOperations.includes('PropertyInsurances_Update')

  const {
    isError: isErrorInsurances,
    isLoading: isLoadingInsurances,
    data,
  } = usePropertyInsurances(property.uuid)

  const insurances = data?.insurances ?? []

  const { data: insuranceTypes } = useInsuranceTypes()

  const { data: allowedInsuranceTypes } = useAllowedInsuranceTypes({
    propertyTypeCode: property.type_code,
  })

  const { data: currencyCodesData } = useCurrencyCodes()

  const currencyCodes = currencyCodesData
    ? [''].concat(currencyCodesData.currency_codes.map((currencyCode) => currencyCode.key))
    : []

  const { data: valuations } = useMultiPropertyValuations([property.uuid])
  const reinstatementCost =
    valuations?.valuations?.[property.uuid]?.REINSTATEMENT_COST?.value_amount

  const reinstatementCostText = reinstatementCost
    ? `${tPropertyInsurancesTable('reinstatement-costs')}: ${formatNumber(
        reinstatementCost.number,
      )} ${reinstatementCost.currency}`
    : ''

  const { data: insurers } = useBusinessPartnerMiniByIds(
    uniq(insurances.map((insurance) => insurance.partyId).filter((partyId) => !!partyId)),
  )

  const [isSearchInsurerDialogOpen, setIsSearchInsurerDialogOpen] = useState(false)
  const [searchInsurerDialogRowKey, setSearchInsurerDialogRowKey] = useState(0)

  const openSearchInsurerDialog = (rowKey) => {
    setIsSearchInsurerDialogOpen(true)
    setSearchInsurerDialogRowKey(rowKey)
  }

  const [insurerSearchKey, setInsurerSearchKey] = useState('')

  const initialSorting = { columnKey: 'id', orderBy: 'asc' }

  const translateSortSettingToSortParameter = (sortSetting) =>
    `${sortSetting?.orderBy === 'asc' ? '+' : '-'}${sortSetting?.columnKey}`

  const { data: insurerSuggestions } = useMatchingBusinessPartnersByNameOrId({
    searchKey: insurerSearchKey,
    minLength: 3,
    excludeInactive: true,
    sort: translateSortSettingToSortParameter(initialSorting),
  })

  const getBusinessPartnerById = (bpId) =>
    insurers?.businessPartnerMinis?.find((insurer) => insurer.id === bpId)

  const [editRows, setEditRows] = useState([])
  const findEditRow = (rowKey) => ({ ...editRows.find((editRow) => editRow.rowKey === rowKey) })

  const removeChangeFromEditRows = (rowKey) => {
    setEditRows([...editRows.filter((editRow) => editRow.rowKey !== rowKey)])
  }

  const handleCancelEdit = (rowKey) => {
    removeChangeFromEditRows(rowKey)
  }

  const queryClient = useQueryClient()

  const onInsuranceChangeSuccess = () => {
    queryClient.invalidateQueries(['property-insurance', property.uuid])
  }

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [errorDialogText, setErrorDialogText] = useState('')

  const onInsuranceChangeError = async (error) => {
    setIsErrorDialogOpen(true)
    const errorDetails = await formatHookError(error)
    setErrorDialogText(errorDetails)
  }

  const mutationBehavior = {
    onSuccess: onInsuranceChangeSuccess,
    onError: (error) => onInsuranceChangeError(error),
  }

  const createInsurance = useCreatePropertyInsurance(mutationBehavior)

  const updateInsurance = useUpdatePropertyInsurance(mutationBehavior)

  const deleteInsurance = useDeletePropertyInsurance(mutationBehavior)

  const checkIfAnythingChanged = (rowKey, row) => {
    let changeDetected = false
    const editRow = findEditRow(rowKey)
    Object.entries(editRow)?.forEach(([key, value]) => {
      if (key !== 'rowKey' && row[key] !== value) {
        changeDetected = true
      }
    })
    return changeDetected
  }

  const getCurrentInsurer = (rowKey, row) => {
    const originalInsurer = getBusinessPartnerById(row['partyId'])
    const changedInsurer = findEditRow(rowKey)?.insurer
    return changedInsurer ? changedInsurer : originalInsurer
  }

  const getCurrentFieldValue = ({ rowKey, originalValue, fieldName }) => {
    const editRow = findEditRow(rowKey)
    const curVal = Object.prototype.hasOwnProperty.call(editRow, fieldName)
      ? editRow[fieldName]
      : originalValue
    return curVal
  }

  const initialValidNumberInputs = {
    insuranceSumAmount: true,
    insurancePremiumAmount: true,
  }
  const [validNumberInputs, setValidNumberInputs] = useState({})

  const checkIsRowValid = (rowKey, row) => {
    let isValid = true
    if (!checkIfAnythingChanged(rowKey, row)) {
      return false
    }
    const hasFailedInternalValidity =
      rowKey in validNumberInputs && Object.values(validNumberInputs[rowKey]).includes(false)
    if (hasFailedInternalValidity) {
      return false
    }
    const fieldsToCheckForEmpty = [
      'insuranceType',
      'insuranceCurrency',
      'insuranceStartDate',
      'insuranceExpiryDate',
      'insurerRemarks',
      'insuranceSumAmount',
    ]
    fieldsToCheckForEmpty.forEach((fieldName) => {
      if (
        !getCurrentFieldValue({
          rowKey,
          originalValue: row[fieldName],
          fieldName,
        })
      ) {
        isValid = false
      }
    })
    if (
      getCurrentFieldValue({
        rowKey,
        originalValue: row['insuranceSumAmount'],
        fieldName: 'insuranceSumAmount',
      }) <= 0
    ) {
      isValid = false
    }
    return isValid
  }

  const getNewEditRow = ({ rowKey, parameter: fieldName, value, oldRow }) => {
    const newRow = oldRow ? { ...oldRow } : { rowKey: rowKey }
    newRow[fieldName] = value
    return newRow
  }

  const updateEditRow = (rowKey, fieldName, value, isValidNumberInput) => {
    //if the row is new in editMode, no corresponding editRow object exists yet -> Create and add it to the state array
    if (isEmpty(findEditRow(rowKey))) {
      const newEditRow = getNewEditRow({ rowKey, parameter: fieldName, value })
      setEditRows([...editRows, newEditRow])
    } else {
      //if the row was already in editMode, change the corresponding entry in the state and leave the rest as it was
      const editRowsUpdated = editRows.map((oldRow) => {
        if (oldRow.rowKey !== rowKey) {
          return { ...oldRow }
        }
        return getNewEditRow({ rowKey, parameter: fieldName, value, oldRow })
      })
      setEditRows([...editRowsUpdated])
    }

    if (isValidNumberInput !== undefined) {
      setValidNumberInputs((prevValidNumberInputs) => {
        const currentRow =
          rowKey in prevValidNumberInputs ? prevValidNumberInputs[rowKey] : initialValidNumberInputs
        currentRow[fieldName] = isValidNumberInput
        return {
          [rowKey]: currentRow,
          ...prevValidNumberInputs,
        }
      })
    }
  }

  const { columnDefinitions, tableData, newRow } = PropertyInsurancesTableConfig({
    insuranceTypes,
    allowedInsuranceTypes,
    currencyCodes,
    insurers: insurers?.businessPartnerMinis,
    insurances,
    openSearchInsurerDialog,
    getCurrentFieldValue,
    getCurrentInsurer,
    setInsurerSearchKey,
    insurerSuggestions,
    updateEditRow,
    checkIsRowValid,
    checkIfAnythingChanged,
  })

  const handleSaveRow = (rowKey) => {
    const editedRow = findEditRow(rowKey)
    const originalRow = {
      ...tableData.find((tableRow) => tableRow.rowKey === rowKey)?.originalRowData,
    }
    const insuranceObjectToSave = {
      ...originalRow,
      ...editedRow,
    }
    if (editedRow.insurer) {
      insuranceObjectToSave.partyId = editedRow.insurer.id ?? ''
    }
    if (rowKey === rowKeyNewRow) {
      createInsurance.mutate({ propertyUuid: property.uuid, insurance: insuranceObjectToSave })
    } else {
      updateInsurance.mutate({ propertyUuid: property.uuid, insurance: insuranceObjectToSave })
    }
    removeChangeFromEditRows(rowKey)
  }

  const handleDeleteRow = (rowKey) => {
    const insuranceToDelete = tableData.find(
      (tableRow) => tableRow.rowKey === rowKey,
    )?.originalRowData

    deleteInsurance.mutate({
      propertyUuid: property.uuid,
      insuranceUuid: insuranceToDelete.insuranceUuid,
    })
  }

  return (
    <>
      <CardWithDisplayAndEditTable
        cardTitle={tPropertyInsurancesTable('insurance')}
        subTitle={reinstatementCostText}
        tableData={tableData}
        newRow={newRow}
        isError={isErrorInsurances}
        isLoading={isLoadingInsurances}
        columnDefinitions={columnDefinitions}
        handleDeleteRow={handleDeleteRow}
        handleSaveRow={handleSaveRow}
        handleCancelEditRow={handleCancelEdit}
        userIsAllowedToEdit={userIsAllowedToEdit}
        actionCellStyles={{
          editModeCell: styles['action-cell-edit'],
        }}
      />
      {createPortal(
        <>
          <RentRollBusinessPartnerSearchDialog
            open={isSearchInsurerDialogOpen}
            initialSearch={''}
            onChange={(businessPartner) => {
              updateEditRow(searchInsurerDialogRowKey, 'insurer', {
                ...businessPartner,
                fullName: businessPartner.name,
              })
              setIsSearchInsurerDialogOpen(false)
            }}
            onClose={() => setIsSearchInsurerDialogOpen(false)}
            withCreateOption={false}
            translatedTypeName={tPropertyInsurancesTable('insurer')}
          />
          {isErrorDialogOpen && (
            <ErrorMessageBoxWithExpandableDetails
              messageSummary={tPropertyInsurancesTable('error.save')}
              messageDetails={errorDialogText}
              isOpen={isErrorDialogOpen}
              onClose={() => setIsErrorDialogOpen(false)}
            />
          )}
        </>,
        document.body,
      )}
    </>
  )
}

export default PropertyInsurancesTable
