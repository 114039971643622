import { SuggestionItem } from '@fioneer/ui5-webcomponents-react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import InputWithSearchSuggestions from 'components/ui/input/InputWithSearchSuggestions'
import useStaffMembers from 'hooks/services/business-partners/staff-members/useStaffMembers'
import useAnalyticalTableIndexHelper from 'hooks/services/conditions/tables/useAnalyticalTableIndexHelper'
import {
  conditionAssigneeChanged,
  requirementAssigneeChanged,
} from 'redux/slices/conditions/conditionCreationSlice'

const minSearchCharacters = 1

const ConditionCreationAssigneeCell = ({
  row: {
    id,
    index,
    depth,
    original: { assignee },
  },
}) => {
  const [userSearchKey, setUserSearchKey] = useState(assignee?.name)
  const dispatch = useDispatch()

  const { isConditionsCell, conditionIndex, requirementIndex } = useAnalyticalTableIndexHelper({
    id,
    index,
    depth,
  })

  useEffect(() => {
    if (!assignee) {
      setUserSearchKey('')
    }
  }, [assignee])

  const { data: suggestions, isError: isSuggestionsError } = useStaffMembers({
    name: userSearchKey,
    minLength: minSearchCharacters,
  })

  const handleOnChange = useCallback(
    ({ detail: { item: selectedUser } }) => {
      const userId = selectedUser.getAttribute('data-user-id')
      const userName = selectedUser.getAttribute('data-user-name')
      const newAssignee = { id: userId, name: userName }

      if (isConditionsCell) {
        dispatch(conditionAssigneeChanged({ conditionIndex, assignee: newAssignee }))
        return
      }
      dispatch(
        requirementAssigneeChanged({ conditionIndex, requirementIndex, assignee: newAssignee }),
      )
    },
    [dispatch, conditionIndex, isConditionsCell, requirementIndex],
  )
  const handleOnClear = useCallback(() => {
    if (isConditionsCell) {
      dispatch(conditionAssigneeChanged({ conditionIndex, assignee: undefined }))
      return
    }
    dispatch(requirementAssigneeChanged({ conditionIndex, requirementIndex, assignee: undefined }))
  }, [dispatch, conditionIndex, isConditionsCell, requirementIndex])

  const renderSuggestions = useCallback(() => {
    const suggestionsItems = []
    suggestions?.staffMembers.forEach((user, staffMemberIndex) => {
      suggestionsItems.push(
        <SuggestionItem
          key={staffMemberIndex}
          text={user.fullName}
          data-user-id={user.id}
          data-user-name={user.fullName}
        />,
      )
    })

    return suggestionsItems
  }, [suggestions])

  return (
    <InputWithSearchSuggestions
      initialValue={userSearchKey}
      renderSuggestions={renderSuggestions}
      handleOnChange={handleOnChange}
      handleOnClear={handleOnClear}
      setSearchKey={setUserSearchKey}
      isSuggestionsError={isSuggestionsError}
    />
  )
}

ConditionCreationAssigneeCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    depth: PropTypes.number.isRequired,
    original: PropTypes.shape({
      assignee: PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
}

export default ConditionCreationAssigneeCell
