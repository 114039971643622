import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  FlexBoxWrap,
  ToolbarSeparator,
} from '@fioneer/ui5-webcomponents-react'
import PropType from 'prop-types'
import styles from 'components/ui/card/CardHeaderWithButtons.module.css'

const CardHeaderWithButtons = ({
  gridArea,
  title,
  subTitle,
  children,
  titleActions,
  messageStrip,
  className,
  titleClassName,
}) => (
  <div
    style={gridArea ? { gridArea: gridArea } : {}}
    className={className ?? styles['custom-card-title']}
  >
    {!!messageStrip && <div className={styles['message-strip-container']}>{messageStrip}</div>}
    <FlexBox
      direction={FlexBoxDirection.Row}
      wrap={FlexBoxWrap.Wrap}
      alignItems={subTitle ? FlexBoxAlignItems.Start : FlexBoxAlignItems.Center}
      justifyContent={FlexBoxJustifyContent.SpaceBetween}
      className={styles.flexWrapper}
    >
      <FlexBox alignItems={FlexBoxAlignItems.Center} className={styles.flexWrapper}>
        <FlexBox direction={FlexBoxDirection.Column}>
          <div className={titleClassName ?? styles['custom-card-title-text']}>{title}</div>
          {!!subTitle && <div className={styles['custom-card-sub-title-text']}>{subTitle}</div>}
        </FlexBox>
        {!!titleActions?.length && (
          <>
            <ToolbarSeparator key="separator-0" />
            {titleActions}
          </>
        )}
      </FlexBox>

      <FlexBox
        data-testid="custom-card-header-children"
        direction={FlexBoxDirection.Row}
        justifyContent={FlexBoxJustifyContent.End}
        alignItems={FlexBoxAlignItems.Center}
        className={styles.flexWrapperChildren}
      >
        {children}
      </FlexBox>
    </FlexBox>
  </div>
)

CardHeaderWithButtons.propTypes = {
  gridArea: PropType.string,
  title: PropType.oneOfType([PropType.string, PropType.node]).isRequired,
  subTitle: PropType.oneOfType([PropType.string, PropType.node]),
  children: PropType.any,
  titleActions: PropType.arrayOf(PropType.element),
  messageStrip: PropType.node,
  className: PropType.string,
  titleClassName: PropType.string,
}
export default CardHeaderWithButtons
