const conditionAssigneeChanged = (
  state,
  { payload: { conditionIndex, assignee: newAssignee } },
) => {
  if (!state.conditions[conditionIndex]) {
    return
  }
  state.conditions[conditionIndex].assignee = newAssignee
}

export default conditionAssigneeChanged
