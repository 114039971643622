import sortBy from 'lodash.sortby'
import { DateTime } from 'luxon'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import useCurrentMultiPropertyKpis from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpis'
import useCurrentMultiPropertyKpisList from 'hooks/services/properties/kpis/useCurrentMultiPropertyKpisList'
import usePortfolioHistoricalRentRollKeyDates from 'hooks/services/properties/rent-roll/usePortfolioHistoricalRentRollKeyDates'
import { PropertyContext } from 'routes/properties/PropertyContext'
import { PropertyPortfolioContext } from 'routes/properties/portfolio/PropertyPortfolioContext'

const extractKpisFromSingle = (responseObject) =>
  responseObject?.data?.keyDateToMultiPropertyRentRollKpis
const extractKpisFromList = (responseObject) =>
  responseObject?.[0]?.data?.keyDateToMultiPropertyRentRollKpis

/**
 * fetches historical rent roll KPIs of the last 5 years
 * per usage types and for all given properties
 */
const useHistoricalRentRollKpisByUsageType = () => {
  const propertyContext = useContext(PropertyContext)
  const propertiesContext = useContext(PropertyPortfolioContext)
  const propertyUuids = propertyContext
    ? [propertyContext.property.uuid]
    : propertiesContext.properties.map((property) => property.uuid)

  const selectedCurrency = useSelector(
    (state) => state.properties.commonCurrency.selectedCommonCurrency,
  )

  const fetchKpisOptions = {
    currency: selectedCurrency,
    withTenantGranularity: true,
    withSegmentUsageTypeGranularity: true,
  }

  const fetchKpisListOptions = {
    currency: selectedCurrency,
    withSegmentUsageTypeGranularity: true,
  }

  // step 1: get KPIs by usage types of the latest rent roll, so we have its key date
  const {
    data: currentUsageTypeKpis,
    isLoading: isLoadingCurrentUsageTypeKpis,
    isError: isErrorCurrentUsageTypeKpis,
  } = useCurrentMultiPropertyKpis(propertyUuids, fetchKpisOptions)

  const currentRentRollKeyDate = useMemo(
    () => currentUsageTypeKpis?.data?.keyDateToMultiPropertyRentRollKpis?.[0]?.keyDate,
    [currentUsageTypeKpis],
  )

  // step 2: get key dates of all historical rent rolls (excluding the latest one)
  const {
    data: historicalRentRollKeyDates,
    isLoading: isLoadingHistoricalRentRollKeyDates,
    isError: isErrorHistoricalRentRollKeyDates,
  } = usePortfolioHistoricalRentRollKeyDates({
    propertyUuids,
  })

  // step 3: calculate key dates for which the historical rent roll data shall be fetched
  const historicalRentRollKeyDatesToFetch = useMemo(() => {
    if (!(currentRentRollKeyDate && historicalRentRollKeyDates?.dates)) {
      return []
    }

    const allHistoricalRentRollKeyDates = historicalRentRollKeyDates.dates.map(
      (datesEntry) => datesEntry.keyDate,
    )
    const fiveYears = { years: 5 }
    const currentRentRollKeyDateMinus5Years = DateTime.fromISO(currentRentRollKeyDate)
      .minus(fiveYears)
      .toISODate()
    const historicalRentRollKeyDatesFromLast5Years = allHistoricalRentRollKeyDates.filter(
      (historicalRentRollKeyDate) => historicalRentRollKeyDate >= currentRentRollKeyDateMinus5Years,
    )

    return historicalRentRollKeyDatesFromLast5Years
  }, [currentRentRollKeyDate, historicalRentRollKeyDates])

  const shouldFetchHistoricalUsageTypeKpis = historicalRentRollKeyDatesToFetch.length > 0

  // step 4: get historical KPIs with segmentUsageTypeGranularity
  const {
    data: historicalUsageTypeKpis,
    isLoading: isLoadingHistoricalUsageTypeKpis,
    isError: isErrorHistoricalUsageTypeKpis,
  } = useCurrentMultiPropertyKpisList(
    [
      {
        propertyUuids,
        ...fetchKpisListOptions,
        keyDates: [...historicalRentRollKeyDatesToFetch],
      },
    ],
    { enabled: shouldFetchHistoricalUsageTypeKpis },
  )

  //step 5: Concatenate and reformat KPIs
  const isLoadingAllUsageTypeKpis =
    isLoadingCurrentUsageTypeKpis ||
    isLoadingHistoricalRentRollKeyDates ||
    (shouldFetchHistoricalUsageTypeKpis && isLoadingHistoricalUsageTypeKpis)

  const isErrorAllUsageTypeKpis =
    isErrorCurrentUsageTypeKpis ||
    isErrorHistoricalRentRollKeyDates ||
    (shouldFetchHistoricalUsageTypeKpis && isErrorHistoricalUsageTypeKpis)

  const allUsageTypeKpis = useMemo(() => {
    if (!(currentUsageTypeKpis && historicalRentRollKeyDates)) {
      return null
    }
    if (shouldFetchHistoricalUsageTypeKpis && !historicalUsageTypeKpis) return null

    const mergedUsageTypesAndPropertyData = sortBy(
      [
        ...(extractKpisFromSingle(currentUsageTypeKpis) ?? []),
        ...(extractKpisFromList(historicalUsageTypeKpis) ?? []),
      ],
      'keyDate',
    )
    return mergedUsageTypesAndPropertyData
  }, [
    historicalUsageTypeKpis,
    currentUsageTypeKpis,
    historicalRentRollKeyDates,
    shouldFetchHistoricalUsageTypeKpis,
  ])

  return {
    isLoading: isLoadingAllUsageTypeKpis,
    isError: isErrorAllUsageTypeKpis,
    data: allUsageTypeKpis,
  }
}

export default useHistoricalRentRollKpisByUsageType
