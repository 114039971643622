import {
  FlexBox,
  FlexBoxAlignItems,
  FlexBoxDirection,
  FlexBoxJustifyContent,
  Title,
  TitleLevel,
} from '@fioneer/ui5-webcomponents-react'
import { useQueryClient } from '@tanstack/react-query'
import { first } from 'lodash'
import orderBy from 'lodash.orderby'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsCard.module.css'
import PropertyRentRollSegmentsCardHeaderActions from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsCardHeaderActions'
import PropertyRentRollSegmentsHeaderActions from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsHeaderActions'
import PropertyRentRollSegmentsTable from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsTable'
import {
  PERIODS,
  ADDED,
  TOTAL_AREA,
  isDeletable,
  findSegmentKpisByPropertyUuid,
  findAreaMeasureUnitCodeByPropertyUuid,
} from 'components/domains/properties/rent-roll/segment/PropertyRentRollSegmentsUtils'
import Card from 'components/ui/card/Card'
import CardHeaderWithButtons from 'components/ui/card/CardHeaderWithButtons'
import LoadingContent from 'components/ui/content/LoadingContent'
import ErrorMessageBoxWithExpandableDetails from 'components/ui/dialog/ErrorMessageBoxWithExpandableDetails'
import LoadingStateWrapper from 'components/ui/screens/LoadingStateWrapper'
import useMultiPropertySegmentKpis from 'hooks/services/properties/kpis/useMultiPropertySegmentKpis'
import { useSegmentsTableResetEditState } from 'hooks/services/properties/segments/segments-table/useSegmentsTableResetEditState'
import { useSegmentsTableSaveHandler } from 'hooks/services/properties/segments/segments-table/useSegmentsTableSaveHandler'
import { useCreateUpdateOrDeleteSegments } from 'hooks/services/properties/segments/useCreateUpdateOrDeleteSegments'
import useMultiplePropertiesSegments from 'hooks/services/properties/segments/useMultiplePropertiesSegments'
import { usePropertiesPermissions } from 'hooks/services/properties/usePropertiesPermissions'
import { formatHookError } from 'hooks/services/useHookErrorResponseFormatter'
import { removeUuidsFromInvalidEditStates } from 'redux/slices/properties/segmentsTableSlice'

const PropertyRentRollSegmentsCard = ({ properties }) => {
  const { t } = useTranslation()

  // Data
  const propertyUuids = [...properties.map((property) => property.uuid)]

  const isMultiProperty = propertyUuids?.length > 1

  const DESC = 'desc'

  // Hooks
  const dispatch = useDispatch()

  const queryClient = useQueryClient()

  const { isLoading: isLoadingPermissions, data: propertyPermissions } = usePropertiesPermissions()
  const allowedPropertyOperations = propertyPermissions?.allowed_operations ?? []

  const isAllowedToUpdatePropertySegments =
    allowedPropertyOperations.includes('PropertySegments_Update')

  const { invalidEditStates } = useSelector((state) => state.properties.segmentsTable)
  const isInvalidEdit = invalidEditStates.length > 0

  const { t: tSegments } = useTranslation('translation', {
    keyPrefix: 'pages.property.rent-roll.segments',
  })

  const { isLoading: isLoadingSegmentKpis, data: multiPropertySegmentKpisData } =
    useMultiPropertySegmentKpis(
      propertyUuids.map((propertyUuid) => ({ property_uuid: propertyUuid })),
      {
        withTenantGranularity: true,
      },
    )

  const {
    mutate: createUpdateOrDeleteSegments,
    isError: isErrorCreateUpdateOrDeleteSegments,
    error: errorCreateUpdateOrDeleteSegments,
  } = useCreateUpdateOrDeleteSegments()

  const {
    isLoading: isLoadingSegments,
    isError: isErrorSegments,
    data: segmentsData,
  } = useMultiplePropertiesSegments(propertyUuids)

  // Hook dependent data
  const segments = useMemo(() => orderBy(segmentsData?.segments, TOTAL_AREA, DESC), [segmentsData])

  const segmentsTitle = `${tSegments('segments')} (${segments?.length})`

  // State
  const [addedCounter, setAddedCounter] = useState(0)
  const [period, setPeriod] = useState(PERIODS.ANNUALLY)
  const [inEditMode, setInEditMode] = useState(false)
  const [segmentsInEdit, setSegmentsInEdit] = useState([])
  const [selectedSegments, setSelectedSegments] = useState([])
  const [isMutating, setIsMutating] = useState(false)
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false)
  const [saveErrorDetails, setSaveErrorDetails] = useState(undefined)

  useEffect(() => {
    if (isErrorCreateUpdateOrDeleteSegments) {
      const asyncSetSaveErrorDetails = async () =>
        setSaveErrorDetails(await formatHookError(errorCreateUpdateOrDeleteSegments))
      asyncSetSaveErrorDetails()
      setIsErrorMessageOpen(true)
    }
  }, [errorCreateUpdateOrDeleteSegments, isErrorCreateUpdateOrDeleteSegments])

  // Hook and state dependent data
  const resetEditState = useSegmentsTableResetEditState({
    segments,
    setSegmentsInEdit,
  })

  // Functions
  const findAreaMeasureUnitCodeByPropertyUuidCallback = useCallback(
    (propertyUuid) => findAreaMeasureUnitCodeByPropertyUuid(propertyUuid, properties),
    [properties],
  )

  const segmentsInEditWithAreaMeasurementUnit = useMemo(
    () =>
      segmentsInEdit?.map((segmentInEdit) => {
        const { property_uuid } = segmentInEdit
        return {
          ...segmentInEdit,
          area_measure_unit_code: findAreaMeasureUnitCodeByPropertyUuidCallback(property_uuid),
        }
      }),
    [findAreaMeasureUnitCodeByPropertyUuidCallback, segmentsInEdit],
  )

  // Handlers
  const handleSave = useSegmentsTableSaveHandler({
    propertyUuids,
    segmentsInEdit: segmentsInEditWithAreaMeasurementUnit,
    createUpdateOrDeleteSegments,
    queryClient,
    setInEditMode,
    setIsMutating,
  })

  const handleCancel = () => {
    setInEditMode(false)
    resetEditState()
  }

  const handleAdd = () => {
    const propertyUuid = first(propertyUuids)
    setSegmentsInEdit((prev) => [
      { uuid: ADDED + addedCounter, property_uuid: propertyUuid },
      ...prev,
    ])
    setAddedCounter((prev) => prev + 1)
  }

  const handleAddByProperty = (propertyUuid) => {
    setSegmentsInEdit((prev) => [
      { uuid: ADDED + addedCounter, property_uuid: propertyUuid },
      ...prev,
    ])
    setAddedCounter((prev) => prev + 1)
  }

  const handleDelete = () => {
    setSegmentsInEdit((prev) => [
      ...prev.filter((segment) => !selectedSegments.includes(segment.uuid)),
    ])
    dispatch(removeUuidsFromInvalidEditStates(selectedSegments))
    setSelectedSegments([])
  }

  const handlePeriodChange = (changedPeriod) => {
    setPeriod(changedPeriod)
  }

  const handleSelectSegment = (segmentUuid) => {
    setSelectedSegments((prev) => [...prev, segmentUuid])
  }

  const handleUnselectSegment = (segmentUuid) => {
    setSelectedSegments((prev) => [...prev.filter((uuid) => uuid !== segmentUuid)])
  }

  const handleSelectAll = () => {
    setSelectedSegments([
      ...segmentsInEdit
        .filter((segment) =>
          isDeletable(
            segment.uuid,
            findSegmentKpisByPropertyUuid(segment.property_uuid, multiPropertySegmentKpisData),
          ),
        )
        .map((segment) => segment.uuid),
    ])
  }

  const handleUnselectAll = () => {
    setSelectedSegments([])
  }

  const handleSegmentInEditChange = (segmentInEdit) => {
    setSegmentsInEdit((prev) => [
      ...prev.map((segment) => {
        if (segment.uuid === segmentInEdit.uuid) {
          return { ...segmentInEdit }
        } else {
          return segment
        }
      }),
    ])
  }

  // Multi property specific handlers
  const handleSelectAllByProperty = (propertyUuid) => {
    setSelectedSegments((prev) => [
      ...prev,
      ...segmentsInEdit
        .filter(
          (segment) =>
            segment.property_uuid === propertyUuid &&
            isDeletable(
              segment.uuid,
              findSegmentKpisByPropertyUuid(segment.property_uuid, multiPropertySegmentKpisData),
            ) &&
            !prev.includes(segment.uuid),
        )
        .map((segment) => segment.uuid),
    ])
  }

  const handleUnselectAllByProperty = (propertyUuid) => {
    setSelectedSegments((prev) => [
      ...prev.filter((uuid) => {
        const segment = segmentsInEdit.find((s) => s.uuid === uuid)
        return segment.property_uuid !== propertyUuid
      }),
    ])
  }

  const isLoading = isLoadingPermissions || isLoadingSegments || isMutating || isLoadingSegmentKpis

  return (
    <Card
      className={styles.propertyRentRollSegmentsCard}
      header={
        <CardHeaderWithButtons title={tSegments('structure')}>
          <LoadingContent
            contentKey="property-rent-roll-segments-card-header-actions"
            isLoading={isLoading}
            isError={isErrorSegments}
          >
            <PropertyRentRollSegmentsCardHeaderActions
              isAllowedPropertySegmentsUpdate={isAllowedToUpdatePropertySegments}
              inEditMode={inEditMode}
              disabled={isInvalidEdit}
              onEdit={() => {
                resetEditState()
                setInEditMode(true)
              }}
              onSave={handleSave}
              onCancel={handleCancel}
            />
          </LoadingContent>
        </CardHeaderWithButtons>
      }
    >
      <LoadingStateWrapper
        isError={isErrorSegments}
        isLoading={isLoading}
        renderContent={() => (
          <FlexBox direction={FlexBoxDirection.Column}>
            <FlexBox
              className={styles.propertyRentRollSegmentsCardPadding}
              direction={FlexBoxDirection.Row}
              alignItems={FlexBoxAlignItems.Center}
              justifyContent={FlexBoxJustifyContent.SpaceBetween}
            >
              <Title level={TitleLevel.H4} className="sapTextColor">
                {segmentsTitle}
              </Title>
              <PropertyRentRollSegmentsHeaderActions
                isMultiProperty={isMultiProperty}
                inEditMode={inEditMode}
                period={period}
                onPeriodChange={handlePeriodChange}
                onAdd={handleAdd}
                onDelete={handleDelete}
                selectedSegments={selectedSegments}
              />
            </FlexBox>
            <FlexBox className={styles.propertyRentRollSegmentsTablePadding}>
              <PropertyRentRollSegmentsTable
                inEditMode={inEditMode}
                properties={properties}
                segments={segments}
                segmentsInEdit={segmentsInEdit}
                onSegmentInEditChange={handleSegmentInEditChange}
                multiPropertySegmentKpis={multiPropertySegmentKpisData}
                period={period}
                selectedSegments={selectedSegments}
                onSelectSegment={handleSelectSegment}
                onUnselectSegment={handleUnselectSegment}
                onSelectAll={handleSelectAll}
                onUnselectAll={handleUnselectAll}
                onSelectAllByProperty={handleSelectAllByProperty}
                onUnselectAllByProperty={handleUnselectAllByProperty}
                onAddByProperty={handleAddByProperty}
              />
            </FlexBox>
          </FlexBox>
        )}
      />
      {createPortal(
        <>
          {isErrorMessageOpen && (
            <ErrorMessageBoxWithExpandableDetails
              messageSummary={t('components.cards.save-error')}
              messageDetails={saveErrorDetails}
              isOpen={isErrorMessageOpen}
              onClose={() => {
                setIsErrorMessageOpen(false)
              }}
            />
          )}
        </>,
        document.body,
      )}
    </Card>
  )
}
PropertyRentRollSegmentsCard.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default PropertyRentRollSegmentsCard
